// App.js
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle'; // For the account icon
import './App.css';
import Home from './pages/Home';
import SupabaseContext, { supabase } from './components/SupabaseClientContext';
import Navbar from './components/Navbar';
// import { SubmitAgentModal } from './components/SubmitAgent';
import UserContext from './components/UserContext';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoutes';
import Footer from './components/Footer';
import ContactForm from './pages/ContactForm';
import { Toaster } from 'react-hot-toast';
import UserGuide from './pages/UserGuide';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [session, setSession] = useState(null);
  // const [submitGptModalIsVisible, setSubmitGptModalIsVisible] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const onLogin = (session, user) => {
    setUserProfile(user)
    setSession(session)
  }
  // Mock functions to handle login/logout - replace with real authentication logic
  const onLogout = async () => {
    try {
      await supabase.auth.signOut()
      setUserProfile(null)
      setSession(null)
    } catch (error) {
      console.log("Failed to logout with error", error)
    }
  };

  const getSession = async () => {
    try {
      const { data } = await supabase.auth.getSession();
      return data?.session
    } catch (e) {
      console.log("Error getting session with error", e)
    }
    
  }

  useEffect(() => {
    console.log("calling use effect")
    const checkAuth = async () => {
      // Check auth status
      const currentSession = await getSession();
      setSession(currentSession);
      const currentUser = currentSession?.user;
      var profileData = {}
      if (currentUser) {
        console.log("current user id is", currentUser.id)
        onLogin(currentSession, currentUser);
        const response = await supabase
        .from('user_profile')  // Replace with your actual table name
        .select('plan, service_email')
        .eq('id', currentUser.id)
        .single()
        if (response.error) {
            console.error('Error fetching user profile:', response.error);
        }

        profileData = response.data;
        console.log("profile data is", profileData);
      }
      setUserProfile({
        ...currentUser,
        ...profileData
      })
      // Listen for auth changes on:
      // SIGNED_IN, SIGNED_OUT, TOKEN_REFRESHED, USER_UPDATED, USER_DELETED, PASSWORD_RECOVERY
      const { subscription } = supabase.auth.onAuthStateChange(
          async (event, session) => {
              if (event === 'SIGNED_IN') {
                setSession(session);
                setUserProfile(session?.user || null);
              } else if (event === 'SIGNED_OUT') {
                setSession(null)
                setUserProfile(null)
              }
          }
      );
      return subscription
    }

    checkAuth().then((subscription) => {
      setLoading(false);
      return () => {
        subscription?.unsubscribe();
      };
    }).catch(e => {
      setLoading(false);
      console.log("Failed to check auth with error", e)
    })
  }, []);

  if (loading) {
    console.log("loading")
  } else {
    console.log("not loading")
    console.log("session")
  }

  return loading? (
    <div className='loading-page'>
      <CircularProgress />
    </div>
  ) :(
    <Router>
      <UserContext.Provider value={{userProfile, setUserProfile}}>
      <SupabaseContext.Provider value={supabase}>
      <div className="App">
          
        <div>
            <Routes>
              <Route path="/contact" element={
                <>
                  <Navbar mode="home"/>
                  <ContactForm />
                </>
              } />
              {/* <Route path="/dashboard" element={
                <ProtectedRoute session={session}>
                  <>
                    <Navbar mode="white"/>
                    <Dashboard onLogout={onLogout} />
                  </>
                </ProtectedRoute>
              } /> */}
              <Route path="/" element={
                  <>
                    <Navbar mode="home"/>
                    <Home />
                  </>
              } />
            </Routes>
        </div>
        {/* <Footer /> */}
      </div>
      <Toaster position="top-right"/>
      </SupabaseContext.Provider>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
