export const buttonStyle = { 
    width: 'fit-content', 
    padding: '6px 16px', 
    border: "2px solid black",
    fontFamily: "Gilroy-Bold",
    fontSize: "20px",
    height: "35px",
    color: "white",
    backgroundColor: '#5551ff', // light green color
    '&:hover': {
    backgroundColor: '#7774ff', // slightly darker green on hover
    },
}

export const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    padding: 2,
    transition: 'transform 0.1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
    },
    cursor: 'pointer',
    borderRadius: '8px',
};
