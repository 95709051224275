import React from 'react';
import { createClient } from '@supabase/supabase-js';

// Initialize your Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Create a Context
const SupabaseContext = React.createContext(supabase);

export default SupabaseContext;
