import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "./ContactForm.css"
import { supabase } from '../components/SupabaseClientContext';
import { useNavigate } from 'react-router-dom';

const buttonStyle = { 
    width: 'fit-content', 
    padding: '6px 16px', 
    border: "2px solid black",
    color: "black",
    fontFamily: "Gilroy-Bold",
    fontSize: "20px",
    height: "35px",
    color: "white",
    backgroundColor: '#5551ff', // light green color
    '&:hover': {
    backgroundColor: '#7774ff', // slightly darker green on hover
    },
    margin: "1rem auto 0 auto"
}

const intents = {
  DEMO: "DEMO",
  ENTERPRISE: "ENTERPRISE",
  OTHER: "OTHER"
}

function ContactForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    companyName: '',
    position: '',
    phoneNumber: '',
    intent: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    // Simulate an API call
    const { data, error } = await supabase.from("requests").insert({
      "full_name": formData.fullName,
      "email_address": formData.email,
      "company_name": formData.companyName,
      "position": formData.position,
      "phone_number": formData.phoneNumber,
      "intention": formData.intent,
    })
    if (error) {
      console.log("Error submitting request data", error)
    } else {
      console.log("submitted request data", data)
    }
    // After successful form submission logic
    console.log(formData);
    setLoading(false);
    setSubmitted(true);
  };

  return submitted ? (
    // <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
    <div className='request-demo-container'>
        <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
        <p className='description'>
            We have received your request and will be in contact shortly.
        </p>
    </div>) : (
    <Container maxWidth="sm" className='request-demo-container'>
      <Typography variant="h4" component="h1" gutterBottom className='title'>
        Contact Us
      </Typography>
      <Typography variant="h4" component="h1" gutterBottom className='description'>
        We need some basic information of you before reaching out
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            required
            label="Full name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
          />
          <TextField
            required
            type="email"
            label="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            required
            label="Company Name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
          />
          <TextField
            required
            label="Position"
            name="position"
            value={formData.position}
            onChange={handleChange}
          />
          <TextField
            label="Phone Number (optional)"
            name="phoneNumber"
            type="tel"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-autowidth-label">What can we do for you</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={formData.intent}
              onChange={handleChange}
              autoWidth
              name="intent"
              label="What can we do for you"
            >
              <MenuItem value={intents.DEMO}>I want to book a demo</MenuItem>
              <MenuItem value={intents.ENTERPRISE}>I want to select enterprise plan</MenuItem>
              <MenuItem value={intents.OTHER}>Other</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" sx={buttonStyle} type="submit">
            Submit Request
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default ContactForm;
