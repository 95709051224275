import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SupabaseContext from '../components/SupabaseClientContext';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';

const Login = () => {
    const supabase = useContext(SupabaseContext);
    const navigate = useNavigate();
  
    useEffect(() => {
      // Listen for auth changes on:
      // SIGNED_IN, SIGNED_OUT, TOKEN_REFRESHED, USER_UPDATED, USER_DELETED, PASSWORD_RECOVERY
      const { subscription } = supabase.auth.onAuthStateChange(
          async (event, session) => {
            console.log("authentication event changed", event)
            console.log(event, session)
            if (event === 'SIGNED_IN') {
              navigate('/dashboard#home'); // Replace with your desired path
            }
          }
      );
      // Unsubscribe on cleanup
      return () => {
        subscription?.unsubscribe();
      };
    }, [navigate]);
  
    return (
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={[]}
          // providerScopes={{
          //   google: 'https://www.googleapis.com/auth/calendar.readonly',
          // }}
        />
    );
  };

export default Login;