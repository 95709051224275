// CheckoutForm.js
import React, { useState, useContext } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { PaymentElement, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { supabase } from './SupabaseClientContext';
import UserContext from './UserContext';
import "./CheckoutForm.css"
import toast from 'react-hot-toast';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const buttonStyle = {
  width: 'fit-content', 
  padding: '6px 16px', 
  border: "2px solid black",
  color: "black",
  fontFamily: "Gilroy-Bold",
  fontSize: "20px",
  height: "35px",
  color: "white",
  backgroundColor: '#5551ff', // light green color
  '&:hover': {
  backgroundColor: '#7774ff', // slightly darker green on hover
  },
  mt: 2
}

const AddPaymentMethodWrapper = ({onPaymentSuccess}) => {
  const [clientSecret, setClientSecret] = useState("")
  const [loading, setLoading] = useState(false);

  const handleAddPaymentMethod = async () => {
    setLoading(true)
    try {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        setLoading(false);
        return;
      }
      const token = data?.session?.access_token;
      const response = await axios.get(`${process.env.REACT_APP_APP_SERVER_ENDPOINT}/create_setup_intent`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log("response is", response);
      if (response?.data?.success) {
        setClientSecret(response?.data?.client_secret);
      } else {
        toast(response?.data?.message, {icon: "❌"})
      }
    } catch (error) {
      console.log("Failed to finalize setup with error", error)
      toast("Internal error, please check back later", {icon: "❌"})
    }
    setLoading(false)
  }

  if (loading) {
    return <CircularProgress />
  } else {
    if (!clientSecret) {
      return (
        <Button variant="contained" color="success" onClick={handleAddPaymentMethod} disabled={ loading }
          sx={buttonStyle}>
            Add Payment Method
        </Button>
      );
    } else {
      const appearance = { /* appearance */ };
      const options = {
        clientSecret: clientSecret,
        // appearance and other options if needed
      };
      return (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm onPaymentIntentConfirmed={() => {
            setClientSecret(false);
            onPaymentSuccess();
          }}/>
        </Elements>

      );
    }
  }
}

const CheckoutForm = ({onPaymentIntentConfirmed}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { userProfile } = useContext(UserContext);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = async (event) => {
    console.log("submitting payment info")
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      toast("Internal error, please come back in a while", {"icon": "❌"});
      return;
    }

    console.log("stripe loaded")
    const paymentElement = elements.getElement(PaymentElement);
    // console.log("payment element is", paymentElement)

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });
    // Poll for subscription done information, this will only be useful when onboarding a user
    var i = 0;
    while (i < 20) {
      console.log("In loop")
      // Define a function that checks for the stripe_subscription_id
      const {data, error} = await supabase
        .from("user_profile")
        .select(`onboarded`)
        .eq("id", userProfile?.id)
        .single();
      console.log("onboarded is", data)
      
      if (data && data.onboarded) {
        // If stripe_subscription_id exists, clear the interval and possibly do more actions
        console.log("User onboarded");
        onPaymentIntentConfirmed();
        break
        // Here, you can add more code to handle the found stripe_subscription_id
      } else {
        console.log("User not onborded yet");
      }
      i++;
      await sleep(1000); // Wait for 1 second
    }
    // // TODO https://github.com/darthjaja6/evawork/issues/2
    // try {
    //   const { data, error } = await supabase.auth.getSession();
    //   if (error) {
    //     return;
    //   }
    //   const token = data?.session?.access_token;
    //   console.log(userProfile?.email)
    //   const response = await axios.put(`${process.env.REACT_APP_APP_SERVER_ENDPOINT}/finalize_subscription/`, {}, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Bearer ${token}`
    //     }
    //   });
    //   console.log("response is", response);
    //   if (response?.data?.success) {
    //     console.log("success")
    //     onPaymentIntentConfirmed()
    //   } else {
    //     toast(response?.data?.message, {"icon": "❌"});
    //   }
    // } catch (error) {
    //   console.log("Failed to finalize setup with error", error)
    //   toast("Internal error, please come back in a while", {"icon": "❌"});
    // }

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: paymentElement,
    // });

    
    // console.log("payment method created", paymentMethod)
    // if (error) {
    //   console.log('[error]', error);
    //   toast("Internal error, please come back in a while", {"icon": "❌"});
    // } else {
    //   // Send paymentMethod.id to the server
    //   console.log("sending payment id", paymentMethod.id)
    //   try {
    //     const { data, error } = await supabase.auth.getSession();
    //     if (error) {
    //       return;
    //     }
    //     const token = data?.session?.access_token;
    //     console.log(userProfile?.email)
    //     const response = await axios.post(`${process.env.REACT_APP_APP_SERVER_ENDPOINT}/add_payment_method/`, {
    //       payment_method_id: paymentMethod.id,
    //     }, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${token}`
    //       }
    //     });
    //     console.log("response is", response);
    //     if (response?.data?.success) {
    //       onPaymentSuccess(paymentMethod.id, response?.data?.last4, response?.data?.brand);
    //     } else {
    //       toast(response?.data?.message, {"icon": "❌"});
    //     }
    //   } catch (error) {
    //     console.log("Failed to finalize setup with error", error)
    //     toast("Internal error, please come back in a while", {"icon": "❌"});
    //   }
    // }
  };

  return (
  <Box>
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button variant="contained" color="success" onClick={handleSubmit} disabled={ !stripe}
        sx={buttonStyle}>
          Save
      </Button>
    </form>
  </Box>)
};

export default AddPaymentMethodWrapper;
