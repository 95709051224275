import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Login from '../components/Login';
import "./Home.css"
import { buttonStyle } from '../styles';

const Home = () => {
  // const supabase = useContext(SupabaseContext);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Listen for auth changes on:
  //   // SIGNED_IN, SIGNED_OUT, TOKEN_REFRESHED, USER_UPDATED, USER_DELETED, PASSWORD_RECOVERY
  //   const { subscription } = supabase.auth.onAuthStateChange(
  //       async (event, session) => {
  //           console.log(event, session)
  //           if (event === 'SIGNED_IN') {
  //             navigate('/'); // Replace with your desired path
  //           }
  //       }
  //   );
  //   // Unsubscribe on cleanup
  //   return () => {
  //     subscription?.unsubscribe();
  //   };
  // }, [navigate]);

  return (
    <div className='home-container'>
      <div className='head-container'>
        {/* <div className='login-container'>
          <Login />
        </div> */}
        <div className='head-data-container'>
          <p className='head-slogan'>AI assistant for ship operator</p>
          <p className='head-description'>Save your time, let Eva lift the heavy</p>
          <div className='head-feature-list'>
            <p className='head-bulletpoint'>Secure - your data will only be used for your task</p>
            <p className='head-bulletpoint'>Smart  - communicates just like your well-trained employee</p>
            <p className='head-bulletpoint'>Speed - does paperwork 10x faster than human</p>
          </div>
        </div>
      </div>
      <Button variant="contained" sx={{...buttonStyle, borderColor: "white", height: "40px"}} onClick={() => {navigate('/contact');}}>
        Book a Demo
      </Button>
      <p className='feature-title'>Email your charter party and bill of lading to Eva, get LOI in minutes</p>
      <div className='demo-container'>
        <img src="./images/send_cp_cropped.png" className="shadowbox"></img>
          <ArrowForwardIcon sx={{
            fontSize: { xs: 30, md: 60}}}/>
        <img src="./images/reply_cropped.png" className="shadowbox"></img>
      </div>
    </div>
  );
};

export default Home;
